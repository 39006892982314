import React, { Component } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { headerUpdate, setHeaderEventFlag, getHeaderEventFlag } from '../utils/common-util';
import '../scss/header.scss';
import Logo from './logo';

class Header extends Component {

  headerEventAdded = false;

  componentDidMount() {
    if(!getHeaderEventFlag()) {
      setHeaderEventFlag();
      window.addEventListener("scroll", headerUpdate, false);
    }
  }

  render() {
    const onBurgerClick = (e) => {
      const el = e.currentTarget;
      const target = el.dataset.target;
      const $target = document.getElementById(target);
      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');
    };

    return (<header>
      <nav id="main-navbar" className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <AniLink to="/">
            <Logo name="white"/>
            <Logo name="color"/>
          </AniLink>
  
          <button className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="main-navbar-menu" onClick={onBurgerClick}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>
  
        <div id="main-navbar-menu" className="navbar-menu">
          <div className="navbar-end">
            <AniLink className="navbar-item nav-home" to="/" activeClassName="active-link">
              HOME
            </AniLink>
            <AniLink className="navbar-item nav-covid19" to="/covid19/" activeClassName="active-link">
              COVID-19
            </AniLink>
            <div className="navbar-item nav-products has-dropdown is-hoverable">
              <AniLink className="navbar-link is-arrowless" to="/iris/">
                PRODUCTS
              </AniLink>
              <div className="navbar-dropdown is-boxed">
                <div className="navbar-item dropdown nested">
                  <div className="dropdown-trigger">
                    <AniLink className="navbar-item nav-iris" to="/iris/" activeClassName="active-link" aria-haspopup="true" aria-controls="dropdown-menu">
                      IRIS
                    </AniLink>
                    <span className="icon">
                      <i className="fas fa-angle-right"></i>
                    </span>
                  </div>
                  <div className="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                      <AniLink className="navbar-item" to="/irishse/" title="Health and Safety Executive" activeClassName="active-link">
                        For HSE
                      </AniLink>
                      {/* <AniLink className="navbar-item" to="/iriswarehouse/" title="Warehouse Safety Executive" activeClassName="active-link">
                        For Warehouse
                      </AniLink> */}
                    </div>
                  </div>
                </div>
                <AniLink className="navbar-item" to="/silverline/" activeClassName="active-link">
                  Silverline
                </AniLink>
                <AniLink className="navbar-item" to="/silverlinemdm/" activeClassName="active-link">
                  Silverline MDM
                </AniLink>
                <OutboundLink className="navbar-item"
                  href="https://anonymisation.ai/home"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Anonymisation
                </OutboundLink>
              </div>
            </div>
            <div className="navbar-item nav-about has-dropdown is-hoverable">
              <AniLink className="navbar-link is-arrowless" to="/about/">
                ABOUT
              </AniLink>
              <div className="navbar-dropdown is-boxed">
                <AniLink className="navbar-item" to="/about/" activeClassName="active-link">
                  About Us
                </AniLink>
                <AniLink className="navbar-item" to="/usecases/" activeClassName="active-link">
                  Use Cases
                </AniLink>
              </div>
            </div>
            <AniLink className="navbar-item nav-careers" to="/careers/" activeClassName="active-link">
              CAREERS
            </AniLink>
            <AniLink className="navbar-item nav-contact" to="/contact/" activeClassName="active-link">
              CONTACT
            </AniLink>
            <hr />
          </div>
        </div>
      </nav>
    </header>);
  }
}

export default Header;
