import React, { Component } from "react"
import classNames from "classnames"
import HubspotForm from "react-hubspot-form"

import { updateFloatBtn } from "../utils/common-util"
import Header from "./header"
import Footer from "./footer"

class Layout extends Component {
  interval = null;

  constructor(props) {
    super(props)
    this.state = {
      formOpened: false,
      formSubmitted: false,
      redirectCount: 0
    }

    this.toggleForm = this.toggleForm.bind(this)
    this.onFormSubmitted = this.onFormSubmitted.bind(this)
  }

  toggleForm = () => {
    updateFloatBtn(!this.state.formOpened)
    this.setState({
      formOpened: !this.state.formOpened,
      formSubmitted: false,
    })
  }

  onFormSubmitted = () => {
    this.setState({
      formSubmitted: true,
    })
    setTimeout(this.toggleForm, 3000)
  }

  componentDidMount = () => {
    let _count = JSON.parse(sessionStorage.getItem('REDIRETION_COUNT'));
    this.setState({
      redirectCount: (30 - parseInt(_count || 0)),
    });
    this.interval = setInterval(() => {
      let count = JSON.parse(sessionStorage.getItem('REDIRETION_COUNT'));
      count = parseInt(count || 1);
      this.setState({
        redirectCount: (30 - count),
      })
      if(count >= 30) {
        clearInterval(this.interval);
        sessionStorage.setItem('REDIRETION_COUNT', null);
        window.location.href = 'https://www.sparkcognition.com';
      } else {
        sessionStorage.setItem('REDIRETION_COUNT', JSON.stringify(++count));
      }
    }, 1000)
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  render() {
    return (
      <>
        <Header />
        <main id="main-container" className="main-container header-visible">
          <div className="spark-notif-wrap">
            <div className="spark-notif-txt">
              We are excited to share that Integration Wizards has been acquired by SparkCognition, a global leader in AI solutions perfected for business. Redirecting in {this.state.redirectCount} seconds.
            </div>
            <div>
              <a className="button is-info spark-read-more" href="https://www.sparkcognition.com">
                <span>Go Now</span>
              </a>
            </div>
          </div>
          {this.props.children}
          <div
            id="float-cont-btn"
            className={classNames({
              "form-visible": this.state.formOpened,
              "form-submitted": this.state.formSubmitted,
            })}
          >
            <button
              className="button is-outlined is-small form-close"
              onClick={this.toggleForm}
            >
              <span className="icon">
                <i className="fas fa-times"></i>
              </span>
            </button>
            {this.state.formOpened ? (
              <HubspotForm
                portalId="7797396"
                formId="3a97b746-d5f2-4077-ab3c-f21eefda3458"
                onFormSubmitted={this.onFormSubmitted}
                onFormSubmit={() => {}}
                loading={<div>Please wait...</div>}
              />
            ) : (
              <button
                className="button is-primary form-open is-medium"
                onClick={this.toggleForm}
              >
                <span className="icon">
                  <i className="fas fa-envelope fa-lg"></i>
                </span>
              </button>
            )}
          </div>
        </main>
        <Footer />
      </>
    )
  }
}

export default Layout
