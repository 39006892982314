import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Logo = ({ name }) => {
  const data = useStaticQuery(graphql`
    query {
      white: file(relativePath: { eq: "iw-logo-full-white.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      color: file(relativePath: { eq: "iw-logo-full.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data[name].childImageSharp.fixed}
      alt="iWizards Logo"
      imgStyle={{ objectFit: "contain" }}
    />
  )
}

export default Logo
