import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "../scss/footer.scss"
import Logo from "../components/logo"

const Footer = () => (
  <footer>
    <div className="columns">
      <div className="column">
        <div className="footer-logo-wrap">
          <div className="footer-logo">
            <Logo name="white"></Logo>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="social-media-icons">
          <div className="smi-title">
            <AniLink className="policy-link" to="/antislaverypolicies/">
              Anti-slavery Statement
            </AniLink>
            <AniLink className="policy-link" to="/policies/">
              Privacy Policy
            </AniLink>
            <span>Follow us: </span>
          </div>
          <div className="smi-icons">
            <OutboundLink
              href="https://www.linkedin.com/company/10034684/admin"
              rel="noopener noreferrer"
              target="_blank"
              className="smi-icon no-text linkedin"
            >
              IW Linkedin
            </OutboundLink>
            <OutboundLink
              href="https://twitter.com/iWizardsLtd"
              rel="noopener noreferrer"
              target="_blank"
              className="smi-icon no-text twitter"
            >
              IW Twitter
            </OutboundLink>
            <OutboundLink
              href="https://www.facebook.com/iwizardsolutions/?ref=aymt_homepage_panel&eid=ARBDOgZQtvRx1H8eYzCg3gjtfbEPkZMyzZ3_g78qWydMSeAHxI_a1JCNTpqVBynyMIVX69LN0KOSKxnv"
              rel="noopener noreferrer"
              target="_blank"
              className="smi-icon no-text facebook"
            >
              IW Facebook
            </OutboundLink>
            <OutboundLink
              href="https://www.instagram.com/integrationwizardssolutions"
              rel="noopener noreferrer"
              target="_blank"
              className="smi-icon no-text instagram"
            >
              IW Instagram
            </OutboundLink>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
